<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editRole') }}</h5>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.active') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputSwitch v-model="role.active" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="role.name" @input="checkRoleName" :disabled="this.isEdit()" @blur="checkUsernameAvailability()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.displayName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="role.displayName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <Fieldset :legend="$t('system.roleMenus')" :toggleable="true" :collapsed="true" class="p-mb-2">
                    <div v-for="(label, index) in roleMenus.labels" :key="'label' + index">
                        <h6>{{ $t(label.label) }}</h6>
                        <div v-for="(menu, index) in label.menus" :key="menu + index">
                            <div v-if="notNullOrEmptyObject(menu.submenus)">
                                <div class="p-field-checkbox" v-for="(submenu, index) in menu.submenus" :key="submenu + index">
                                    <Checkbox v-model="submenu.roleAccess" :binary="true" />
                                    <label>{{ $t(submenu.displayName) }}</label>
                                </div>
                            </div>
                            <div class="p-field-checkbox" v-else>
                                <Checkbox v-model="menu.roleAccess" :binary="true" />
                                <label>{{ $t(menu.displayName) }}</label>
                            </div>
                        </div>
                    </div>
                </Fieldset>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { debounce } from 'lodash';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'roles',
            errorKeyCount: 0,
            role: {
                active: false,
            },
            roleMenus: { id: '', routes: [] },
            showMenusData: false,
            selectedMenus: {},
        };
    },
    mounted() {
        this.BoApi.getMainMenuRoleAccess(this.$route.params.id)
            .then((responseMenus) => {
                this.roleMenus = responseMenus.data;

                if (this.isEdit()) {
                    this.BoApi.getRole(this.$route.params.id).then((responseRole) => {
                        this.role = responseRole.data;
                        this.showMenusData = true;
                    });
                } else {
                    this.showMenusData = true;
                }
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
                this.errorText = error;
                this.showError = true;
                this.errorKeyCount++;
            });
    },
    methods: {
        submitForm: function () {
            this.disableSubmit = true;
            let roleDetails = {
                roleId: this.role._id,
                mainMenu: {
                    labels: this.roleMenus.labels,
                },
            };
            this.BoApi.saveRole(this.role)
                .then((response) => {
                    roleDetails.roleId = response.data;
                    this.BoApi.saveMainMenuRoleAccess(roleDetails).then(() => {
                        this.goto(this.backRoute);
                    });
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        checkRolenameAvailability() {
            this.BoApi.isRoleNameAvailable(this.role.name)
                .then(() => {
                    this.showError = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response.status === 409) {
                        this.errorText = this.$t('system.alerts.roleNameTaken');
                        this.showError = true;
                    }
                });
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        checkRoleName: debounce(function () {
            this.checkRolenameAvailability();
        }, 800),
    },
};
</script>
